<template>
    <div class="box box-primary">
      <div class="box-header with-border" ng-click="isCollapsedSearchParameter = !isCollapsedSearchParameter" ng-init="isCollapsedSearchParameter = false;">
       
          <h3 class="box-title">Add Contacts Link with this Account</h3>
          <span aria-hidden="true" class="glyphicon pull-right glyphicon-chevron-right glyphicon-chevron-right__expanded" ng-class="{'glyphicon-chevron-right__expanded': !isCollapsedSearchParameter}"></span>
      </div>
      <div class="box-body">
       <ContactSearchParameter></ContactSearchParameter>
      </div>
      <footer class="box-footer text-left">
          <button type="submit" class="btn btn-primary btn-sm" ng-disabled="submitted" ng-click="submitted = true">
              Search
              <i class="fa fa-fw fa-arrow-circle-right"></i>
          </button>
          <button type="button" class="btn btn-link btn-sm">Reset</button>
      </footer>
    </div>
    <div class="box box-info">
       <div class="box-header with-border">
         
          <h3 class="box-title head-title-padding">Contacts Linked with this Account</h3>
        </div>
        <div class="box-body table-responsive">
          <div class="notdatatable form-inline mbottom-15">
              <div class="table-controls table-columns">
                  <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
              </div>
              <div class="table-controls table-pagesize table-columns">
                  <label>
                      Show 
                      <select class="form-control input-xs">
                          <option>5</option>
                          <option>10</option>
                          <option>25</option>
                          <option>50</option>
                          <option>100</option>
                          <option>250</option>
                          <option>500</option>
                          <option>1000</option>
                      </select>
                      entries 
                  </label>
              </div>
              
              <div class="table-controls table-filter">Filter Results  <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
          </div>
          <LinkedContactSearchResults></LinkedContactSearchResults>
          <div class="dataTables_info" id="example1_info" role="status" aria-live="polite">Showing 1 to 3 of 3 entries</div>
          <div class="dataTables_paginate paging_simple_numbers" id="example1_paginate"><ul class="pagination"><li class="paginate_button previous disabled" id="example1_previous"><a href="#" aria-controls="example1" data-dt-idx="0" tabindex="0">Previous</a></li><li class="paginate_button active"><a href="#" aria-controls="example1" data-dt-idx="1" tabindex="0">1</a></li><li class="paginate_button next disabled" id="example1_next"><a href="#" aria-controls="example1" data-dt-idx="2" tabindex="0">Next</a></li></ul></div>
        </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  
  import ContactSearchParameter from '../components/ContactSearchParameter.vue';
  import LinkedContactSearchResults from '../components/LinkedContactSearchResults.vue';
  export default {
    name: 'Search Linked Contact',
    components: {
      ContactSearchParameter,
      LinkedContactSearchResults
    },
    ready () {
      $(document).ready(function ($) {
      })
    }
  }
  </script>
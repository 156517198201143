<template>
<div class="row" v-if="showOption == '' && quick == false">
   <div class="col-md-5">
      <div class="box box-info">
         <div class="box-header with-border">
            <i class="fa fa-users"></i>
            <h3 class="box-title">Company Information</h3>
         </div>
         <div class="box-body">
            <div class="form-horizontal company-detail eq-container">
               <div class="row">
                  <div class="col-md-12 company-name">Example Company, Inc.</div>
               </div>
               <div class="datarow row form-group">
                  <label class="col-md-4 control-label">Address</label>
                  <div class="text ng-binding col-md-7">PO Box 2231 <br> Champaign,IL 61825</div>
               </div>
               <div class="datarow row form-group">
                  <label class="col-md-4 control-label">Phone Number</label>
                  <div class="text ng-binding col-md-7">+217-398-6245</div>
               </div>
               <div class="datarow row form-group">
                  <label class="col-md-4 control-label">Website</label>
                  <div class="text ng-binding col-md-7">www.example.com</div>
               </div>
            </div>

         </div>
      </div>
     
      <div class="form-alert-message" v-show="showEmailAlert">
         <div class="alert alert-info alert-dismissible">
            <button type="button" class="close" v-on:click="showEmailAlert = false">×</button>
            <div class="message-container">
               <div class="icon"><i class="icon fa fa-info-circle"></i></div>
               <div class="message">
                  <strong>Contact does not have valid email address</strong>
                  <div> This contact does not have a valid email address You tried to create an email to this contact, but they don't have a valid address. Update the contact's details with valid email address and try again. </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="col-md-7">
      <div class="box box-success">
         <div class="box-header with-border">
            <i class="fa fa-info"></i>
            <h3 class="box-title">Status Information 
              <Popper arrow>
                <i class="fa fa-question-circle text-info help-icon"></i>
                  <template #content>
                    <div class="tooltiptext-container">
                       This section provides a summary of your interactions with this contact. Click the Last Agent link to see the last comments made to the customer. Click an unresolved question or the View all unresolved questions button to see details.
                    </div>
                  </template>
               </Popper>
            </h3>
         </div>
         <div class="box-body right-container">
            <div class="equal-container">
               <div class="row mbottom-15">
                  <div class="col-4 text-center report-counts">
                     <h3 class="text-green"><a href="" class="text-orange">6</a></h3>
                     <span><a href="" class="text-primary">Questions Past Week </a></span>
                  </div>
                  <div class="col-4 text-center report-counts">
                     <h3 class="text-red"><a href="" class="text-red">1</a></h3>
                     <span><a href="" class="text-primary">Unassigned Questions</a></span>
                  </div>
                  <div class="col-4 text-center report-counts">
                     <h3 class="text-green"><a href="" class="text-green">3</a></h3>
                     <span><a href="" class="text-primary">Assigned Questions </a></span>
                  </div>
               </div>
               <div class="row">
                  <div class="col-12 text-left report-counts">
                     <h3 class="text-blue"><span>Last Agent </span><a href="" class="text-primary">Jacob.smith@1to1service.com</a></h3>
                  </div>
               </div>
               <div class="clearfix"></div>
               <h3>Unresolved Questions <span class="text-muted">(5)</span></h3>
               <ul class="empty-list">
                  <li>
                     <a href="" target="_blank">
                     Need a shipment quote for a full truckload
                     </a>
                     <div class="message-time">2 days ago</div>
                  </li>
                  <li>
                     <a href="" target=" _blank">
                     Trying to track the status of a shipment
                     </a>
                     <div class="message-time">2 days ago</div>
                  </li>
               </ul>
               <div class="view-more">
                  <a class="btn btn-primary btn-xs pull-right">View all unresolved questions</a>
               </div>
            </div>
         </div>
      </div>
      <div class="box box-warning">
         <div class="box-header with-border">
            <i class="fa fa-history"></i>
            <h3 class="box-title"> Recent History </h3>
         </div>
         <div class="box-body">
            <div class="equal-container">
               <div class="qa-message-list">
                  <i class="fa fa-envelope bg-blue"></i>
                  <div class="message-item">
                     <div class="message-inner">
                        <span class="time"><i class="fa fa-clock-o"></i> Just Now</span>
                        <span class="time">
                        <i class="fa fa-user-o"></i>
                        Jacob Smith
                        </span>
                        <div class="message-header">Chat</div>
                        I need help picking a camera
                     </div>
                  </div>
               </div>
               <div class="qa-message-list">
                  <i class="fa fa-ticket bg-orange"></i>
                  <div class="message-item">
                     <div class="message-inner">
                        <span class="time"><i class="fa fa-clock-o"></i> 2 days ago</span>
                        <span class="time">
                        <i class="fa fa-user-o"></i>
                        Alek Packard
                        </span>
                        <div class="message-header">Agent Ticket</div>
                        Best price on LG Plasma
                     </div>
                  </div>
               </div>
               <div class="view-more">
                  <a href="#tab_4" class="btn btn-primary btn-xs pull-right" data-toggle="tab" aria-expanded="true">View all history</a>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="col-md-12">
      <div class="box box-warning">
         <div class="box-header with-border">
            <i class="fa fa-flash"></i>
            <h3 class="box-title">Contacts Linked With This Account</h3>
            <button v-if="showLinkedContact" class="btn btn-primary btn-xs pull-right" v-on:click="showLinkedContact = false">Link Contacts</button>
            <button v-if="!showLinkedContact" class="btn btn-primary btn-xs pull-right" v-on:click="showLinkedContact = true">Back to Contacts</button>
         </div>
         <div class="box-body">
             <div v-if="showLinkedContact">
               <div class="notdatatable form-inline mbottom-15">
                  <div class="table-controls table-columns">
                     <div class="btn-group"><button class="btn btn-default" type="button"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                  </div>
                  <div class="table-controls table-pagesize table-columns">
                     <label>
                        Show 
                        <select class="form-control input-xs">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        </select>
                        entries 
                     </label>
                  </div>
                  
                  <div class="table-controls table-filter">Search<input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                  </div>
                  <table class="table table-striped table-hover">
                     <thead>
                     <tr role="row">
                           <th>&nbsp;</th>
                           <th name="name">Name</th>
                           <th class="sorting"> Email </th>
                           <th class="sorting"> Address </th>
                           <th class="sorting"> Work Phone </th>
                          
                        </tr>
                     </thead>
                     <tbody>
                        <tr role="row" class="odd">
                           <td class="sorting_1">
                              <button class="icon-button button-delete" type="button">
                              <i class="fa fa-times-circle"></i>
                              </button>
                           </td>
                           <td>
                              John Doe
                           </td>
                           <td>
                              john.doe@example.com
                           </td>
                           <td>
                              1243 Somewhere Blvd
                           </td>
                           <td>
                              +40-1000122
                           </td>
                        </tr>
                        <tr role="row" class="even">
                           <td class="sorting_1">
                              <button class="icon-button button-delete" type="button">
                              <i class="fa fa-times-circle"></i>
                              </button>
                           </td>
                           <td>
                              Albert Metricman
                           </td>
                           <td>
                              albert.metricman@example.com
                           </td>
                           <td>
                              1243 Somewhere Blvd
                           </td>
                           <td>
                              +40-10001332
                           </td>
                        </tr>
                     </tbody>

                  </table>
                  <div id="Main_MyDashboard_Recent_TableInfo">
                  <div class="notdatatable table-info">Showing 1 to 2 of 2 entries</div>
                  <div class="dataTables_paginate notdatatable table-pagination text-end">
                     <ul class="pagination">
                        <li><a href="">Previous</a></li>
                        <li class="active"><a href="">1</a></li>
                        <li><a href="">Next</a></li>
                     </ul>
                  </div>
                  </div>
             </div>

             <div v-if="!showLinkedContact">
               <div class="box box-primary">
                     <div class="box-header with-border" ng-click="isCollapsedSearchParameter = !isCollapsedSearchParameter" ng-init="isCollapsedSearchParameter = false;">
                        <i class="fa fa-search"></i>
                        <h3 class="box-title">Search Parameters  <i class="fa fa-question-circle text-info help-icon" popover-placement="auto bottom-right" uib-popover-template="'searchParamTooltip.html'" type="button" popover-trigger="outsideClick" ng-click="$event.stopPropagation();"></i></h3>
                        <span aria-hidden="true" class="glyphicon pull-right glyphicon-chevron-right glyphicon-chevron-right__expanded" ng-class="{'glyphicon-chevron-right__expanded': !isCollapsedSearchParameter}"></span>
                     </div>
                     <div class="box-body">
                     <ContactSearchParameter></ContactSearchParameter>
                     </div>
                     <footer class="box-footer text-left">
                        <button type="submit" class="btn btn-primary btn-sm"  v-on:click="searchList = true">
                           Search
                           <i class="fa fa-fw fa-arrow-circle-right"></i>
                        </button>
                        <button type="button" class="btn btn-link btn-sm">Reset</button>
                     </footer>
               </div>

               <div v-if="searchList">
                    <div class="box box-primary">
                     <div class="box-header with-border">
                                    <i class="fa  fa-users"></i>
                                    <h3 class="box-title head-title-padding">Contact List</h3>
                                  </div>
                        <div class="box-body">
                           <div class="notdatatable form-inline mbottom-15">
                  <div class="table-controls table-columns">
                     <div class="btn-group"><button class="btn btn-default" type="button"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                  </div>
                  <div class="table-controls table-pagesize table-columns">
                     <label>
                        Show 
                        <select class="form-control input-xs">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        </select>
                        entries 
                     </label>
                  </div>
                  
                  <div class="table-controls table-filter">Filter Contacts<input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                  </div>
                  <table class="table table-striped table-hover">
                     <thead>
                        <tr role="row">
                           <th>&nbsp;</th>
                           <th name="name">Name</th>
                           <th class="sorting"> Email </th>
                           <th class="sorting"> Address </th>
                           <th class="sorting"> Work Phone </th>
                          
                        </tr>
                     </thead>

                     <tbody>
                        
                        <tr id="row1" role="row" class="odd">
                           <td name="name" width="30" class="sorting_1">
                             <input type="checkbox" class=""/>
                           </td>
                           <td>
                              <a href="/pages/contact-summary.html">John Doe</a>
                           </td>
                           <td>
                              <a href="/pages/contact-summary.html">john.doe@example.com</a>
                           </td>
                           <td>
                              Example Company, Inc.
                           </td>
                           <td>
                              312-555-5555
                           </td>
                        </tr>

                     </tbody>

                  </table>
                  <div id="Main_MyDashboard_Recent_TableInfo">
                  <div class="notdatatable table-info">Showing 1 to 2 of 2 entries</div>
                  <div class="dataTables_paginate notdatatable table-pagination text-end">
                     <ul class="pagination">
                        <li><a href="">Previous</a></li>
                        <li class="active"><a href="">1</a></li>
                        <li><a href="">Next</a></li>
                     </ul>
                  </div>
                  </div>
                        </div>
                     </div>

                     <div class="col-md-6" ng-show="contactsearch &amp;&amp; subscribe" style="">
                        <button class="btn btn-primary" type="button" ng-click="contactsearch = !contactsearch" ng-init="contactsearch = false">
                        Linked Selected
                        </button>
                        <span>Add only selected contacts to account</span>
                        <br><br>
                        <button class="btn btn-primary" type="button">
                        Link All
                        </button>
                        <span>Add all contacts
                        from this search to this account</span>
                     </div>

               </div>

               
             </div>
         </div>
      </div>
   </div>
</div>

</template>

<script>
import Popper from "vue3-popper";
import AgentEmailContent from '../components/AgentEmailContent.vue';
import Multiselect from '@vueform/multiselect'
import Toggle from '@vueform/toggle'
import InteractionSearchParameter from '../components/InteractionSearchParameter.vue';
import ContactSearchParameter from '../components/ContactSearchParameter.vue';
export default {
  name: 'Account Summary Info',
  components: {
     Toggle,
     Popper,
     AgentEmailContent,
     Multiselect,
     InteractionSearchParameter,
     ContactSearchParameter
  },
 
  data: function(){
      return{
      showEmailAlert : false,
      quick : false,
      showOption : '',
      paymentSuccess : false,
      showemailreceive : true,
      value : 'email1',
      showLinkedContact: true,
      showContactList: false,
      searchList:false
          }
      },
  mounted(){ 
      
        this.emitter.on("hide-view-details", Data => {
         this.quick = false;
         this.showOption = '';
         
        });
  },
  methods:{
     cancelAlert(){
        showEmailAlert = false;
     },
     showQuickAgentEmail(quickActionOption){
         this.quick = false;
         this.showOption = quickActionOption;
     },
     showQuickActions(quickActionOption){
         this.quick = true;
         this.showOption = quickActionOption;    
     },
     cancelQuickActions(){
      this.quick = false;
      this.showOption = '';
      this.paymentSuccess = false;
     }, 
     submitPayment(){
             this.paymentSuccess = true;
          }
  }

}
</script>

<template>
  <SearchLinkedContact></SearchLinkedContact>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
import SearchLinkedContact from '../components/SearchLinkedContact.vue';
export default {
  name: 'Linked Contact',
   components: {
    Multiselect,
    SearchLinkedContact
  },
  setup(){ 
  }

}
</script>
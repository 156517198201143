<template>
    <BreadcrumbContact page-title="Account" name="Company" page-section="Contact"> </BreadcrumbContact>
     <section class="content">
         <AccountSummaryTabs></AccountSummaryTabs>
     </section>
   </template>
   
   <script>
   // @ is an alias to /src
   import BreadcrumbContact from '../components/BreadcrumbContact.vue';
   import AccountSummaryTabs from '../components/AccountSummaryTabs.vue';
   
   export default {
     name: 'Account Summary',
     components: {
       BreadcrumbContact,
       AccountSummaryTabs
     },
     ready () {
       $(document).ready(function ($) {
       })
     }
   }
   </script>
   
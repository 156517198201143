<template>
  <div class="nav-tabs-custom color-tab-icon">
        <ul class="nav nav-tabs">
            <li class="nav-item">
            <a class="nav-link active" href="#contact_summary_tab" data-bs-toggle="tab" data-bs-target="#contact_summary_tab" type="button" role="tab" aria-controls="contact_summary_tab" aria-selected="true">
                <i class="fa fa-navicon orange"></i>
                <span>Summary</span>
            </a>
            </li>
            <li class="nav-item">
            <a class="nav-link" href="#contact_detail_tab" data-bs-toggle="tab" data-bs-target="#contact_detail_tab" type="button" role="tab" aria-controls="contact_summary_tab" aria-expanded="false">
                <i class="fa fa-ellipsis-h blue"></i>
                <span>Details</span>
            </a>
            </li>
            
            <li class="nav-item">
            <a class="nav-link" href="#contact_history_tab" data-bs-toggle="tab" data-bs-target="#contact_history_tab" type="button" role="tab" aria-controls="contact_history_tab" aria-selected="true">
                <i class="fa fa-history green"></i>
                <span>History</span>
            </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#contact_linkedcontact_tab" data-bs-toggle="tab" data-bs-target="#contact_linkedcontact_tab" type="button" role="tab" aria-controls="contact_history_tab" aria-selected="true">
                  <i class="fa fa-users bg-purple-active"></i>
                  <span>Linked Contacts</span>
              </a>
            </li>
           
        </ul>
        <div class="tab-content box-bg-color">
          <div class="tab-pane contact-summary  active" id="contact_summary_tab">
               <AccountSummaryInfo>  </AccountSummaryInfo>
          </div>

          <div class="tab-pane" id="contact_detail_tab">
             <AccountInformation></AccountInformation>
          </div>
          <div class="tab-pane" id="contact_history_tab">
             <HistoryTabContent></HistoryTabContent>
          </div>
          <div class="tab-pane" id="contact_linkedcontact_tab">
             <LinkedContactTabContent></LinkedContactTabContent>
          </div>

        </div>
  </div>
</template>

<script>
// @ is an alias to /src

import AccountInformation from '../components/AccountInformation.vue';
import AccountSummaryInfo from '../components/AccountSummaryInfo.vue';
import  ContactSummaryDescription from '../components/ContactSummaryDescription.vue';
import HistoryTabContent from '../components/HistoryTabContent.vue';
import PaymentsTabContent from '../components/PaymentsTabContent.vue';
import LinkedContactTabContent from '../components/LinkedContactTabContent.vue';



export default {
  name: 'Account Summary Tab',
  components: {
     AccountInformation,
     AccountSummaryInfo,
     ContactSummaryDescription,
     HistoryTabContent,
     PaymentsTabContent,
     LinkedContactTabContent
  },
  ready () {
    $(document).ready(function ($) {
    })
  }
}
</script>
